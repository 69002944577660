<template>
  <ValidationProvider tag="div" class="single-answer column is-half" :rules="validationRules" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field>
          <b-datetimepicker
              placeholder="Click to select..."
              icon="calendar-today"
              :locale="$t('locale')"
              horizontal-time-picker
              :append-to-body="true"
              @input="onChange"
              v-model="data"
          >
          </b-datetimepicker>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'DatetimePicker',
  mixins: [QuestionMixin],
  data () {
    return {
      data: null
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', { [this.question.qid]: value?.toISOString() })
    }
  },
  created () {
    const value = this.value[this.question.qid]
    if (value) this.data = new Date(value)
  }
}
</script>
